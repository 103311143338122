@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MohaveBold";
  src: url("./fonts/Mohave-Bold.otf");
}
@font-face {
  font-family: "MohaveRegular";
  src: url("./fonts/Mohave-Regular.otf");
}
